import request, { applicationJson } from "@/utils/axios";

export function getCampaignList(params) {
  return request.get("/campaign/list", params);
}

export function updateCampaignSatus(params) {
  return request.put("/campaign/status", params);
}

export function delCampaign(params) {
  return request.delete("/campaign/info", params);
}

export function getOption(params) {
  return request.get("/campaign/option", params);
}

export function getAgency() {
  return request.get("/campaign/agency");
}

export function creatCampaign(params) {
  return applicationJson.post("/campaign/info", params);
}

export function uploadCampaign(params) {
  return request.post("/campaign/file", params);
}

export function isCampaignNameExit(params) {
  return request.get("/campaign/name/existence", params);
}

export function addMoreKolListToCampaign(params) {
  return applicationJson.put("/campaign/kol", params);
}

export function validationCampaign(params) {
  return request.post("/campaign/validation", params);
}

// /campaign/type
export function getCampaignType(params) {
  return request.get("/campaign/type", params);
}

// 获取额度
export function getAmount(params) {
  return request.get("/campaign/evaluation/quota", params);
}

export function getCampaignBrand(params) {
  return request.get("/campaign/brand", params);
}

// 获取品线列表 /campaign/brandLine
export function getCampaignBrandLine() {
  return request.get("/campaign/brandLine");
}

// 获取产品列表 /campaign/product
export function getCampaignProduct() {
  return request.get("/campaign/product");
}
